<template>
  <div>
    <div class="text-2xl font-bold">Success</div>
    <div class="text">{{ message }}</div>
  </div>
</template>

<script>

export default {
  props: ['message'],
};
</script>
