<template>
  <button :data-action="text" class="flex w-1/4 h-7 justify-center rounded-md disabled:opacity-50" :class="color" :disabled="disabled">
    {{ text }}
  </button>
</template>


<script>
export default {
  props: ['text', 'color', 'disabled'],
};
</script>
