import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import './assets/tailwind.css'
import Toasted from 'vue-toasted';

Vue.use(VueRouter);
Vue.use(Toasted, {position: 'top-center', duration: '1500'});

const router = new VueRouter({
    routes: [
        { path: '/:token', component: App },
    ]
});

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
