<template>
  <div>
    <div class="text-md md:text-2xl font-bold text-center">{{ title }}</div>
    <div class="">{{ text }}</div>
    <div>
          <textarea
              class="w-full resize-none rounded-md"
              rows="6"
              v-bind:placeholder="commentPlaceholder"
              v-bind:disabled="disabled"
              v-model="comment"
          ></textarea>
    </div>
    <div class="flex flex-row justify-center gap-5">
      <approval-button color="w-full bg-ranpak-green" text="Approve" v-on:click.native="approveButtonClick"
                       v-bind:disabled="disabled"/>
      <approval-button color="w-full bg-red-500" text="Reject" v-on:click.native="approveButtonClick"
                       v-bind:disabled="disabled"/>
    </div>
  </div>
</template>

<script>
import ApprovalButton from "./ApprovalButton.vue";
import axios from 'axios';
import jwt_decode from "jwt-decode";

export default {
  props: ['message'],
  components: {
    ApprovalButton,
  },
  data: function () {
    return {
      disabled: false,
      title: "Contribution calculator approval",
      text: "",
      commentPlaceholder: "Comment",
      comment: "",
    };
  },
  mounted: function () {
    if (!this.$route.params.token) {
      return this.$emit('error', 'Missing token');
    }

    this.token = jwt_decode(this.$route.params.token);
    if (!this.token) {
      return this.$emit('error', 'Invalid token');
    }

    this.text = this.token.text;
  },
  methods: {
    approveButtonClick: function (event) {
      const component = this;

      if (!this.comment) {
        component.$toasted.error('Comment is required');
        return;
      }

      component.disabled = true;
      component.$toasted.info('Loading...', {
        dontClose: true
      });

      axios.post(process.env.VUE_APP_TRAFFIX_URL + '/approval/contributioncalculator', {
        token: this.$route.params.token,
        action: event.target.dataset.action,
        comment: component.comment,
      }).then(function () {
        component.$emit('success', 'Request successfully sent');
      }).catch(function (error) {
        component.$toasted.clear();
        component.$toasted.error(error.response?.data?.error || error || 'Something went wrong');
      }).then(function () {
        component.disabled = false;
      })
    }
  }
};
</script>
