<template>
  <div class="flex flex-col bg-ranpak-blue h-screen p-5">
    <application-logo/>
    <div class="flex justify-center">
      <div class="flex flex-col w-11/12 md:w-1/2 bg-white rounded-md p-10 gap-2">
        <component v-bind:is="component" v-bind:message="message" v-on:error="handleError" v-on:success="handleSuccess"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationLogo from "./components/ApplicationLogo.vue";
import ApprovalForm from "./components/ApprovalForm.vue";
import ErrorMessage from "./components/ErrorMessage.vue";
import SuccessMessage from "./components/SuccessMessage.vue";

export default {
  name: "App",
  components: {
    ApplicationLogo,
    ApprovalForm,
    ErrorMessage,
    SuccessMessage
  },
  data: function () {
    return {
      message: '',
      component: 'ApprovalForm',
    };
  },
  methods: {
    handleError(error) {
      this.message = error;
      this.component = ErrorMessage;
    },
    handleSuccess(message) {
      this.message = message;
      this.component = SuccessMessage;
    }
  }
};
</script>
